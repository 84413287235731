export enum SnackBarVariant {
  error,
  success,
}

export interface ISnackBarFeedback {
  duration?: number;
  feedback?: string;
  visitLink?: string;
  visitLinkText?: string;
  variant: SnackBarVariant;
}
