import React, { FC, useCallback, SyntheticEvent, useMemo, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Autocomplete } from '../../molecules';
import { translate } from '../../../translations/translate';
import { IWithClassName } from '../../../types';
import { useStreetSuggestions } from '../../../hooks';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { validateAddress } from '../../../common/validation/validateAddress';

interface StreetFieldProps extends IWithClassName {
  name: string;
  required?: boolean;
  onClear?(): void;
  onSelectOption?(street: string): void;
  initialValue?: string;
  validationRules?: RegisterOptions;
}

export const StreetField: FC<StreetFieldProps> = ({
  className,
  name,
  required = false,
  onClear,
  onSelectOption,
  initialValue,
  validationRules,
}) => {
  const { control, setValue, setError, clearErrors, trigger, watch } = useFormContext();
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules: { required, minLength: 3 },
  });

  const street = watch(name);
  const { streetSuggest, streetSuggestions } = useStreetSuggestions(name, field.value);

  const onChangeStreet = useCallback(
    async (e: SyntheticEvent<HTMLInputElement>): Promise<void> => {
      const value = e.currentTarget.value;
      streetSuggest(value);
      !value.length && onClear?.();

      e.stopPropagation();
    },
    [onClear, streetSuggest],
  );

  const _onSelectOption = useCallback(
    (street: string): void => {
      setValue(name, street);
      onSelectOption?.(street);
      trigger(name);
    },
    [setValue, name, onSelectOption, trigger],
  );

  const options = useMemo(() => {
    const allStreetSuggestions = field.value && initialValue ? [...streetSuggestions, initialValue] : streetSuggestions;
    return (
      allStreetSuggestions
        // remove duplicates
        .filter((option, index, self) => index === self?.findIndex((value) => value === option))
        .map((suggestion) => ({
          label: suggestion,
          value: suggestion,
        }))
    );
  }, [field.value, initialValue, streetSuggestions]);

  const streetValidation = useCallback(
    async (value?: string) => {
      if (street?.toLowerCase() !== initialValue?.toLowerCase()) {
        setError(name, { type: 'custom', message: translate('locationsform.noStreets') });
      }

      const validatedAddress = await validateAddress(value, undefined, translate('locationsform.noStreets'));

      if (validatedAddress !== true && validatedAddress) {
        setError(name, { type: 'custom', message: validatedAddress as string });
        trigger(name);
      }

      if (validatedAddress === true) {
        clearErrors(name); // clear manual errors and trigger regular validation
        trigger(name);
      }
    },
    [clearErrors, initialValue, name, setError, street, trigger],
  );

  useEffect(() => {
    if (street) {
      streetValidation(street);
      trigger(name);
    }
  }, [name, street, streetValidation, trigger]);

  return (
    <div className={className}>
      <Autocomplete
        {...field}
        filterOnType
        label={translate('sgw.phases.mainLocation.street')}
        meta={{ error: !!error, touched: !!error || isTouched }}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => onChangeStreet(e)}
        onBlur={(e) => {
          const existingOption = options?.find(
            ({ label }) => e.currentTarget.value?.toLowerCase() === label?.toLowerCase(),
          );
          if (existingOption) {
            _onSelectOption(existingOption.value);
          }
        }}
        onSelectOption={_onSelectOption}
        options={options}
        required={required}
        validationRules={validationRules}
      />
    </div>
  );
};
