import * as A from 'antwerp-core-react-branding';
import { InputTypes } from 'antwerp-core-react-branding';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  APP_LINK_INFOFICHE_PARKINGBAN,
  HOUSE_NUMBER_MAX_LENGTH,
  STREET_NUMBER_VALIDATION,
} from '../../common/constants';
import { IReasonForm } from '../../common/form';
import { IRequest, IRequestReason, IWaitableProperties, WorkflowType } from '../../types';
import { ReasonSelector } from '../common/cardselector/ReasonSelector.component';
import { WorkflowButtons } from '../molecules';
import { FormInput } from '../common/forms/FormInput.component';

import { TextArea } from '../common/forms/TextArea.component';
import { Fieldset } from '../atoms';
import { submitId } from '../../common/utils/formUtils';
import { translate, translateHtml } from '../../translations/translate';
import { StreetField } from '../organisms';
import { useSelector } from 'react-redux';
import { selectRequest } from '../../store/selectors';

export type ReasonFormProperties = {
  initialValues?: IRequest;
  onSubmit: (values: IRequest) => void;
  requestReasons: IRequestReason[];
  step: number;
} & IWaitableProperties;

const emptyFormValues: Partial<IRequest> = {
  comment: '',
  // @ts-ignore
  reason: null,
  requestAddress: {
    street: '',
    streetNumber: '',
  },
};

export const ReasonForm = (props: ReasonFormProperties) => {
  const { onSubmit, requestReasons, step } = props;
  const initialValues = useSelector(selectRequest);
  const formMethods = useForm<IReasonForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    shouldFocusError: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    control,
    reset,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    reset({
      ...emptyFormValues,
      ...initialValues,
    });
  }, [initialValues, reset, setValue]);

  const labelLegendType = translate('reasonform.requestTitle');

  const _onChange = useCallback(
    (onChange: any) => (reason: IRequestReason) => {
      onChange(reason);
      clearErrors('reason');
    },
    [clearErrors],
  );

  return (
    <section>
      <FormProvider {...formMethods}>
        {/*@ts-ignore*/}
        <A.Form onSubmit={handleSubmit(onSubmit)} id={submitId(WorkflowType.ASign, step)}>
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>{renderInfo()}</A.Spacing>
          <Fieldset legend={labelLegendType}>
            <div className="a-input is-required">
              <label className="a-input__label">{translateHtml('reasonform.requestTypeQuestion')}</label>
              <Controller
                control={control}
                name="reason"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <ReasonSelector
                      reasons={requestReasons}
                      input={{
                        onChange: _onChange(onChange),
                        value: value || null,
                      }}
                      {...props}
                      error={error}
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </div>
            <div className="row">
              <div className="col-xs-12">
                {/* @ts-ignore */}
                <A.Paragraph spacing>{translate('reasonform.requestAddressTitle')}</A.Paragraph>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-6">
                <StreetField {...register('requestAddress.street', { required: true })} required />
              </div>
              <div className="col-md-3 col-sm-4 col-xs-6">
                <FormInput
                  {...register('requestAddress.streetNumber', {
                    required: true,
                    minLength: 1,
                    pattern: STREET_NUMBER_VALIDATION,
                  })}
                  label={translate('locationsform.streetNumber')}
                  meta={{
                    error: !!errors.requestAddress?.streetNumber,
                    touched: !!touchedFields.requestAddress?.streetNumber || !!errors.requestAddress?.streetNumber,
                  }}
                  type={InputTypes.Text}
                  maxLength={HOUSE_NUMBER_MAX_LENGTH}
                  required
                />
              </div>
            </div>

            {/*@ts-ignore*/}
            <A.Spacing type={A.SpacingStyle.MarginTop}>
              <TextArea
                ariaLabel={translate('reasonform.description')}
                label={renderDescription()}
                TextAreaProps={register('comment', {
                  required: true,
                  minLength: 1,
                })}
                meta={{ error: !!errors.comment, touched: !!touchedFields.comment || !!errors.comment }}
                required
              />
            </A.Spacing>
          </Fieldset>
          <WorkflowButtons step={step} type={WorkflowType.ASign} />
        </A.Form>
      </FormProvider>
    </section>
  );
};

const renderInfo = (): JSX.Element => (
  // @ts-ignore
  <A.Paragraph spacing>
    {translateHtml('reasonform.requestInfo', {
      link: APP_LINK_INFOFICHE_PARKINGBAN,
      linktext: translate('reasonform.infoLink'),
    })}
  </A.Paragraph>
);

const renderDescription = (): JSX.Element => (
  <span>
    {translate('reasonform.requestDescriptionHelp')}
    <br />
    {translate('reasonform.requestDescriptionHelp_headsup')}
  </span>
);
