import {
  INITIALIZE_WORKFLOW,
  NEXT_STEP_GRANTED,
  PREVIOUS_STEP_GRANTED,
  REQUEST_DELETE_PDI,
  REQUEST_RECEIVED,
  REQUEST_RESET_PDI,
  STEP_GRANTED,
} from '../../common/actions/types';
import { CLEAR_REQUEST, REQUEST_TEMP_DELETE } from '../../common/actions/types';
import { IChangePDITypeAction, IGenericAction } from '../actions/actions';
import { IPublicDomainIntakeType, IRequest, IRequestFile } from '../../types';
import { PublicDomainIntakeActions, RequestActions } from '../actions';

const INITIAL_STATE: IRequest | {} = {};

export default function request(
  state: Partial<IRequest> = INITIAL_STATE,
  action: IGenericAction<IRequest> | IChangePDITypeAction<IRequest, number, IPublicDomainIntakeType>,
): IRequest | {} {
  let changePDITypeAction: IChangePDITypeAction<IRequest, number, IPublicDomainIntakeType>;
  let payload: IRequest;
  switch (action.type) {
    case INITIALIZE_WORKFLOW:
    case REQUEST_TEMP_DELETE:
    case CLEAR_REQUEST:
      return INITIAL_STATE;
    case REQUEST_RECEIVED:
      payload = action.payload;
      // Ensure an array
      payload.publicDomainIntakes = payload.publicDomainIntakes || [];
      // Map index to the array
      payload.publicDomainIntakes = payload.publicDomainIntakes.map((x, i) => ({ ...x, index: i }));
      return payload;
    case PublicDomainIntakeActions.save.type:
      return action.payload;
    case REQUEST_DELETE_PDI:
      state.publicDomainIntakes = action.payload.publicDomainIntakes;
      return state;
    case REQUEST_RESET_PDI:
      changePDITypeAction = action as IChangePDITypeAction<IRequest, number, IPublicDomainIntakeType>;
      payload = changePDITypeAction.payload;
      payload.publicDomainIntakes[changePDITypeAction.index] = {
        index: changePDITypeAction.index,
        type: changePDITypeAction.pdiType,
      };
      return payload;
    case NEXT_STEP_GRANTED:
    case PREVIOUS_STEP_GRANTED:
    case STEP_GRANTED:
      return action.payload as IRequest;
    case PublicDomainIntakeActions.storeAttachment.type:
      const { index, file } = action.payload as unknown as { index: number; file: IRequestFile };
      const publicDomainIntakes = (state?.publicDomainIntakes || []).map((pdi) =>
        pdi.index === index ? { ...pdi, attachments: [file] } : pdi,
      );
      return {
        ...state,
        publicDomainIntakes,
      };
    case RequestActions.storeAttachment.type:
      return {
        ...state,
        attachments: action.payload,
      };
    default:
      return state;
  }
}
