import React, { FC } from 'react';
import { INewsflash } from '../../../types';
import * as A from 'antwerp-core-react-branding';
import { createUseStyles } from 'react-jss';

interface IProps extends INewsflash {}

const newsflashLevelToParagraphLevel = {
  info: A.Levels.Primary,
  warning: A.Levels.Warning,
  alert: A.Levels.Danger,
};

const useStyles = createUseStyles({
  newsflash: {
    padding: '10px',
  },
  title: {
    marginBottom: '10px',
  },
});

export const Newsflash: FC<IProps> = ({ level, title, message }) => {
  const C = useStyles();

  return (
    <div className={C.newsflash}>
      {/*@ts-ignore*/}
      <A.Paragraph type={A.ParagraphStyle.Alert} level={newsflashLevelToParagraphLevel[level]}>
        {title && <h6 className={C.title}>{title}</h6>}
        <p>{message}</p>
      </A.Paragraph>
    </div>
  );
};
