import { IWithId } from './generic.types';

export enum FeatureName {
  allowEmergencyRequestForASign = 'allowEmergencyRequestForASign',
  createSgwRequest = 'createSgwRequest',
  integrateDigipolisOrder = 'integrateDigipolisOrder',
  integrateDigipolisSalesIntegration = 'integrateDigipolisSalesIntegration',
  sentryLoggingFrontoffice = 'sentryLoggingFrontoffice',
}

export interface IFeature extends IWithId {
  description: string;
  enabled: boolean;
  name: FeatureName;
}
