import { NewsflashType } from '../../../types';
import React, { FC } from 'react';
import { selectNewsflashes } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import { Newsflash } from '../../atoms';
import { createUseStyles } from 'react-jss';

interface IProps {
  type: NewsflashType;
}

const useStyles = createUseStyles({
  newsflashes: {
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '40px',
  },
});

export const Newsflashes: FC<IProps> = ({ type }) => {
  const C = useStyles();
  const newsflashes = useSelector(selectNewsflashes(type));

  return (
    <div className={C.newsflashes}>
      {newsflashes.map((newsflash) => (
        <Newsflash {...newsflash} />
      ))}
    </div>
  );
};
