import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import { FC } from 'react';
import { PaymentType } from '../../../common/enums';
import { canPayByBankTransfer } from '../../../common/validation/validateBankTransferDate';
import { FeatureName, IRequest } from '../../../types';
import { IControllableForm } from '../../../common/form';
import { RadiobuttonsProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/radiobuttons';
import { RadioButtonsControlled } from '../../atoms';
import { useFeatures } from '../../../hooks';
import { translate } from '../../../translations/translate';

interface IProps extends IControllableForm, Omit<RadiobuttonsProperties, 'options'> {
  value?: string;
  input?: any;
  request: IRequest;
}

export const PaymentTypeSelector: FC<IProps> = (props) => {
  const { isFeatureEnabled: integrateDigipolisSalesIntegration } = useFeatures(
    FeatureName.integrateDigipolisSalesIntegration,
  );

  const getOptions = (): A.RadiobuttonOption[] =>
    Object.values(PaymentType)
      .filter((paymentType) => (integrateDigipolisSalesIntegration ? paymentType === PaymentType.Online : true))
      .map((paymentType: PaymentType) => ({
        disabled: paymentType === PaymentType.Banktransfer && !canPayByBankTransfer(props.request),
        label: translate(`paymenttype.${paymentType}`),
        value: paymentType,
      }));

  return <RadioButtonsControlled {...props} options={getOptions()} />;
};
