import * as A from 'antwerp-core-react-branding';

import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import { createDeleteDraftRequestAction, createDeletePDIAction } from '../../../common/actions/creators/requests';
import { createStepRequestedAction } from '../../../common/actions/creators/workflow';
import { RequestReasons } from '../../../common/enums';
import { intakeToAddressString, isIntakeAddressCompleteForGeoCoding } from '../../../common/utils/geometry.util';
import { LocationsFormItem } from '../../common/forms/locations/locationsFormItem.component';
import { Visible } from '../../common/layout/Visible.component';
import { Confirm } from '../../common/messaging/Confirm.component';
import Price from '../../common/price/price';
import './4-locations.scss';
import { MobileIntakesComponent } from './MobileIntakes.component';
import { PublicDomainIntakeActions } from '../../../store/actions';
import { WorkflowButtons } from '../../molecules';
import { Fieldset } from '../../atoms';
import {
  AsignWorkflowSteps,
  ICost,
  IDispatcheableProperties,
  IPublicDomainIntake,
  IPublicDomainIntakeType,
  IRequest,
  ITenant,
  IWaitableProperties,
  WorkflowType,
} from '../../../types';
import { selectCarFreeZones } from '../../../store/selectors';
import { PublicDomainIntakeIntersectionAlert } from '../../organisms/PublicDomainIntakeIntersectionAlert/PublicDomainIntakeIntersectionAlert.component';

export type LocationFormProperties = {
  initialValues: Partial<IRequest>;
  onPrevious: (value: IRequest) => void;
  onSubmit: (values: IRequest) => void;
  publicDomainIntakeTypes?: IPublicDomainIntakeType[];
  request?: IRequest;
  selectedPublicDomainIntake?: number;
  step: number;
  tenant?: ITenant;
  cost?: ICost;
} & IWaitableProperties &
  IDispatcheableProperties;

export const LocationsForm: FC<LocationFormProperties> = (props) => {
  const { request, step, onPrevious, cost } = props;
  const [selectedIntake, setSelectedIntake] = useState<IPublicDomainIntake>();
  const [editIntakeMode, setEditIntakeMode] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [intakes, setIntakes] = useState<IPublicDomainIntake[]>(
    request?.publicDomainIntakes ? [...request.publicDomainIntakes] : [],
  );

  const dispatch = useDispatch();
  const carFreeZones = useSelector(selectCarFreeZones);

  const onSelectIntake = (selectedIntake: IPublicDomainIntake): void => {
    setSelectedIntake({
      valid: false,
      ...selectedIntake,
    });
    setEditIntakeMode(true);
  };

  const onIntakeSave = (intakes: IPublicDomainIntake[]) => {
    const requestToSave: IRequest = {
      ...request!,
      publicDomainIntakes: intakes,
    };
    dispatch(PublicDomainIntakeActions.save(requestToSave));
  };

  const onIntakeFormCompleted = (intake: IPublicDomainIntake): void => {
    setEditIntakeMode(false);
    if (intake.valid) {
      // AS-4689 Set house numbers to 0 for intake without house numbers
      if (intake.streetNumberUnknown) {
        intake.streetNumberFrom = '0';
        intake.streetNumberTo = '0';
      }

      // Update intake in array
      if (intake.index !== undefined) {
        const newIntakes = intakes.map((item) => (item.index === intake.index ? intake : item));
        setIntakes(newIntakes);
        onIntakeSave(newIntakes);
      }

      // pass additional intake, new intake may not be added to the state yet at this point
      checkSecondLocation(intake);
    }
    setSelectedIntake(undefined);
  };

  const onIntakeDelete = (intake: IPublicDomainIntake): void => {
    let requestToDelete: IRequest;
    let newIntakes = [...intakes];
    const isLastIntake = intakes.length === 1;

    if (!isLastIntake) {
      newIntakes = [...intakes.filter((oldIntake) => oldIntake.index !== intake.index)];
    } else {
      newIntakes[0] = {
        index: 0,
        type: intakes[0].type,
        valid: false,
      } as any;
    }

    newIntakes = newIntakes.map((intake, index) => ({
      ...intake,
      index,
    }));

    requestToDelete = {
      ...request!,
      publicDomainIntakes: newIntakes,
    };

    if (selectedIntake?.index === intake.index) {
      setIntakes(newIntakes);
      setSelectedIntake(undefined);
    } else {
      setIntakes(newIntakes);
    }

    dispatch!(createDeletePDIAction(requestToDelete));

    if (isLastIntake) {
      if (request?.id) {
        dispatch!(createDeleteDraftRequestAction(request));
      }

      dispatch!(createStepRequestedAction(AsignWorkflowSteps.Type));
    }
  };

  const _onSubmit = useCallback(() => {
    request && props.onSubmit(request);
  }, [props, request]);

  // #region private render

  const renderDesktopIntakes = (): JSX.Element[] => {
    return intakes.map((x, i) => (
      <tr key={i}>
        <td style={{ width: '15px', textAlign: 'center' }}>
          {x.valid && <span className="fa fa-check locations__intakes__intake--valid" />}
        </td>
        <td>{x.type ? x.type.name : '-'}</td>
        <td>{intakeHeader(x)}</td>
        <td style={{ textAlign: 'right' }}>
          {x.index! >= 0 && (
            // @ts-ignore
            <A.IconButton
              icon="trash"
              level={A.Levels.Danger}
              type={A.ButtonType.Transparent}
              onClick={() => {
                onIntakeDelete(x);
              }}
            />
          )}
          <A.Button
            size={A.Sizes.Small}
            text={intl.get('locationsform.editlocation')}
            type={A.ButtonType.Transparent}
            onClick={() => onSelectIntake(x)}
          />
        </td>
      </tr>
    ));
  };

  // #endregion

  const onNewLocation = (): void => {
    const intakesToSave = [...intakes];
    // AS-5438: to pre-fill existing data for a new location, the first intake in the list is used
    // will be cleared in the cases that it's not needed, see locationsFormItem.component.tsx
    const firstIntakeData = intakesToSave[0]
      ? {
          city: intakesToSave[0].city,
          street: intakesToSave[0].street,
          streetFlag: intakesToSave[0].streetFlag,
          streetNumberFrom: intakesToSave[0].streetNumberFrom,
          streetNumberTo: intakesToSave[0].streetNumberTo,
          streetNumberUnknown: intakesToSave[0].streetNumberUnknown,
          zipCode: intakesToSave[0].zipCode,
        }
      : undefined;

    if (allIntakesValid(intakesToSave)) {
      const selectedIntakeToSave = {
        index: intakesToSave ? intakesToSave.length : 0,
        valid: false,
        ...firstIntakeData,
      } as IPublicDomainIntake;

      intakesToSave.push(selectedIntakeToSave);
      setIntakes(intakesToSave);
      setSelectedIntake(selectedIntakeToSave);
    }
  };

  const showConfirm = (): void => {
    setConfirmVisible(true);
  };

  const hideConfirm = (): void => {
    setConfirmVisible(false);
  };

  const checkSecondLocation = (intake?: IPublicDomainIntake): void => {
    // Check the reason and if it is a scaffold or container, and there's only one intake, ask for a second
    const reason = request!.reason.reason;

    // add additional intake that isn't added to the state yet
    const allIntakes = intake?.index && !intakes[intake.index] ? intakes.concat(intake) : intakes;

    if (reason === RequestReasons.Container || reason === RequestReasons.Scaffold) {
      const isEmergency = request ? request.emergencyProcedure : false;
      if (allIntakes.length === 1 && !isEmergency) {
        setTimeout(() => showConfirm(), 1000);
      }
    }
  };

  // #endregion

  const allIntakesValid = (intakes: IPublicDomainIntake[]) => !(intakes || []).find((intake) => !intake.valid);
  const noIntakesValid = (intakes: IPublicDomainIntake[]) => !(intakes || []).find((intake) => !!intake.valid);

  const intakeHeader = (intake: IPublicDomainIntake): JSX.Element | string => {
    if (intake) {
      if (isIntakeAddressCompleteForGeoCoding(intake)) {
        return intakeToAddressString(intake, carFreeZones);
      }
    }
    return intl.get('general.dash');
  };

  useEffect(() => {
    !intakes.length && onNewLocation();

    if (noIntakesValid(intakes)) {
      setSelectedIntake(intakes[0]);
    }

    if (request?.publicDomainIntakes?.length === 1 && request.publicDomainIntakes[0].valid) {
      checkSecondLocation();
    }

    // eslint-disable-next-line
  }, []);

  return selectedIntake ? (
    <Visible visible={!!selectedIntake}>
      <LocationsFormItem
        initialRequest={props.initialValues}
        selectedIntake={selectedIntake}
        unselectIntake={() => setSelectedIntake(undefined)}
        onIntakeDelete={onIntakeDelete}
        tenant={props.tenant!}
        onIntakeFormCompleted={onIntakeFormCompleted}
        editMode={editIntakeMode}
      />
    </Visible>
  ) : (
    <>
      <div className="row">
        <Fieldset legend={intl?.get('general.locations')} className="locations col-xs-12 col-md-9">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <div className="intakes-desktop">
              {/*@ts-ignore*/}
              <A.Table small striped spacing>
                <thead>
                  <tr>
                    <th colSpan={2} style={{ width: '30%' }}>
                      {intl.get('general.type')}
                    </th>
                    <th>{intl.get('general.location')}</th>
                    <th style={{ width: '30%' }} />
                  </tr>
                </thead>
                <tbody>{renderDesktopIntakes()}</tbody>
              </A.Table>
            </div>
            <MobileIntakesComponent
              intakes={intakes}
              onDelete={onIntakeDelete}
              onSelect={onSelectIntake}
              header={intakeHeader}
            />
            <PublicDomainIntakeIntersectionAlert />
          </A.Spacing>
          <Visible visible={allIntakesValid(intakes)}>
            {/*@ts-ignore*/}
            <A.IconButton
              icon="plus"
              style={{ width: '14rem', marginBottom: '1.5rem', marginRight: '0.5rem', float: 'right' }}
              text={intl.get('locationsform.newlocation')}
              onClick={onNewLocation}
            />
          </Visible>
          <Confirm
            cancelText={intl.get('general.no') as any}
            message={intl.get('locationsform.addlocation') as any}
            okText={intl.get('general.yes') as any}
            onCancel={hideConfirm}
            onOk={() => {
              hideConfirm();
              onNewLocation();
            }}
            timestamp={confirmVisible ? new Date().getTime() : 0}
            visible={confirmVisible}
          />
        </Fieldset>
        <div className="col-xs-12 col-md-3">
          <Price cost={cost} showGdpr={false} />
        </div>
      </div>
      <div className="row">
        <section className="col-xs-12 col-md-9">
          <Visible visible={!allIntakesValid(intakes)}>
            {/*@ts-ignore*/}
            <A.Spacing type={A.SpacingStyle.MarginBottom}>
              {/*@ts-ignore*/}
              <A.Paragraph type={A.ParagraphStyle.Alert}>{intl.get('locationsform.notallvalid')}</A.Paragraph>
            </A.Spacing>
          </Visible>
          <WorkflowButtons
            nextDisabled={!allIntakesValid(intakes)}
            previousDisabled={!allIntakesValid(intakes)}
            step={step}
            onNext={_onSubmit}
            onPrevious={onPrevious}
            type={WorkflowType.ASign}
          />
        </section>
      </div>
    </>
  );
};
