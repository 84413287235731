import axios from 'axios';
import { IApiPromise, IPublicDomainGeometry, TCarFreeZone } from '../../types';
import { getApiUserUrl } from '../../common/utils/apiUtils';

export const CarFreeZoneApi = {
  fetchAllActive: (): IApiPromise<TCarFreeZone[]> =>
    axios.get(
      getApiUserUrl('v1')('carfreezones?gis_id_set=true&active=true&sort=name&gis_sync_layer[]=17&page_size=1000'),
    ),
  intersects: (geometries: IPublicDomainGeometry[]): IApiPromise<TCarFreeZone[]> => {
    const params = geometries.map((geometry) => `geometry[]=${encodeURIComponent(JSON.stringify(geometry))}`).join('&');

    return axios.get(getApiUserUrl('v1')(`carfreezones/intersects?${params}`));
  },
};
