import { FC, useCallback } from 'react';
import { Newsflashes, Stepper, WorkflowButtons } from '../../molecules';
import * as React from 'react';
import intl from 'react-intl-universal';
import { useWorkflowHistory } from '../../../hooks';
import { createUseStyles } from 'react-jss';
import { SgwWorkflowStep, SGW_WORKFLOW_STEPS, TRouterWithId, WorkflowType } from '../../../types';
import { useSelector } from 'react-redux';
import { getRequestReferenceId } from '../../../store/selectors/sgw';
import { Visible } from '../../common/layout/Visible.component';
import { COLORS } from '../../../theme';
import { useParams } from 'react-router';

interface IProps extends React.PropsWithChildren {
  onPrevious?(): void;
  onNext?(): void;
  step: number;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  type?: WorkflowType;
  title: string;
  onNextLabel?: string;
}
const useStyles = createUseStyles({
  header: {
    marginBottom: '20px',
  },
  referenceNumber: {
    color: COLORS.oliveBlack,
    marginBottom: '1rem',
  },
});

export const WorkflowTemplate: FC<IProps> = ({
  children,
  onPrevious,
  onNext,
  step,
  previousDisabled = false,
  nextDisabled = false,
  type = WorkflowType.Sgw,
  title,
  onNextLabel,
}) => {
  const C = useStyles();
  const { id } = useParams<keyof TRouterWithId>();
  const { goToPreviousPage } = useWorkflowHistory();

  const stepNames = SGW_WORKFLOW_STEPS.map((step) => intl.get(`sgw.workflow.steps.${step}`));
  const referenceId = useSelector(getRequestReferenceId(id));
  const forcePrevious = [SgwWorkflowStep.Attachments, SgwWorkflowStep.Overview].includes(step);

  const _onPrevious = useCallback(() => {
    goToPreviousPage(step);
    onPrevious?.();
  }, [step, goToPreviousPage, onPrevious]);

  const _onNext = useCallback(() => {
    onNext?.();
  }, [onNext]);

  return (
    <>
      <Newsflashes type="SGW" />
      <Stepper steps={stepNames} activeStep={step} />
      <hr aria-hidden />
      <h3 className={C.header}>{title}</h3>
      <Visible visible={!!referenceId}>
        <div className={C.referenceNumber}>{intl.get('sgw.referenceNumber', { referenceId })}</div>
      </Visible>
      {children}
      <WorkflowButtons
        onPrevious={_onPrevious}
        onNext={_onNext}
        step={step}
        nextDisabled={nextDisabled}
        previousDisabled={previousDisabled}
        submit
        type={type}
        onNextLabel={onNextLabel}
        forcePrevious={forcePrevious}
      />
    </>
  );
};
