import { LatLngTuple } from 'leaflet';
import { Environment } from '../config/environment';
import { HttpStatusCodes } from '../types';

// tslint:disable
// Authorization constants
export const OAUTH_SCOPE =
  'astad.aprofiel.v1.username astad.aprofiel.v1.name astad.aprofiel.v1.email astad.aprofiel.v1.phone';
export const STORE_OAUTH_DATA = 'access_data';
export const STORE_OAUTH_TOKEN = 'access_token';
export const STORE_OAUTH_REFRESH = 'refresh_token';
export const SESSION_STOR_ORIG_URL = 'original_url';

// App Constants
export const API_GENERAL_URL = 'general/v1';
export const API_GRANTTYPE_REFRTOKEN = 'refresh_token';
export const API_USER_URL = 'user';
export const API_BACKOFFICE_URL = 'backoffice/v1';
export const API_OAUTH_URL = 'oauth/v2';
export const APP_ALLOWED_FILE_FORMATS = '.docx, .doc, .pdf, .jpeg, .jpg, .png, .svg';
export const APP_CONTACT_DEVIATIONS_DELIJN = 'omleidingen.vvrantwerpen@delijn.be';
export const APP_CONTACT_TIJDELIJKEVERKEERSSIGNAL = 'tijdelijke.verkeerssignalisatie@antwerpen.be';
export const APP_CORPORATE_MAIN_SITE = 'https://www.antwerpen.be';
export const APP_DEFAULT_COUNTRY = 'BE';
export const APP_DEFAULT_CURRENCY = '€';
export const APP_DEFAULT_START_HOUR = 7;
export const APP_DEFAULT_STOP_HOUR = 17;
export const APP_EXTENSION_FORMNAME = 'request-extension';
export const APP_IMAGES = Environment.baseFrontEndUrl + '/assets/images';
export const APP_LINK_EXAMPLE_PLANS =
  'https://assets.antwerpen.be/srv/assets/api/download/9fa99008-c51f-4134-9899-6fdbe032cdc5/Voorbeelden%20van%20situatieschetsen.pdf';
export const APP_LINK_INFOFICHE_CARFREE =
  'https://www.antwerpen.be/info/588f4905b4ce55b1a54a6824/aanvraag-toegang-voetgangerszone-of-autoluwe-zone';
export const APP_LINK_INFOFICHE_MINORCONSTRUCTION =
  'https://www.antwerpen.be/nl/info/588f2ebd2d2a3c5367597ef6/aanvraag-werk-met-beperkte-signalisatie';
export const APP_LINK_INFOFICHE_PARKINGBAN =
  'https://www.antwerpen.be/nl/info/5655c30fb2a8a79f428b45e8/aanvraag-tijdelijk-parkeerverbod-met-parkeerborden';
export const APP_LINK_ONDERNEMEN = 'http://www.ondernemeninantwerpen.be';
export const APP_LINK_REQUIREMENTS =
  'https://www.antwerpen.be/nl/info/588f2ebd2d2a3c5367597ef6/aanvraag-werk-met-beperkte-signalisatie';
export const APP_MAX_ATTACH_SIZE = 4;
export const APP_MAX_HOUR = 24;
export const APP_MIN_HOUR = 0;
export const APP_SHORT_DATE_FORMAT = 'DD-MM';
export const APP_DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_DISPLAY_DATETIME_FORMAT = 'DD/MM/YYYY - HH:mm';
export const APP_DATE_FIELD_FORMAT = 'DD-MM-YYYY';
export const LOCAL_STOR_RESTORE_ALERT = 'temp_request_restore_alert';
export const LOCAL_STOR_TEMP_REQUEST = 'temp_request';
export const MEGABYTE = 1048576;
export const HOUSE_NUMBER_MAX_LENGTH = 8;

export const TENANT_NAME = 'Antwerpen';

// Workflow constants
export const WORKFLOW_MAX_STEP = 6;
export const WORKFLOW_MIN_STEP = 1;
export const WORKFLOW_PATH_NEW = 'new';
export const WORKFLOW_STATE_APPROVED = 'approved';
export const WORKFLOW_STATE_CANCELED = 'canceled';
export const WORKFLOW_STATE_CLOSED = 'closed';
export const WORKFLOW_STATE_DRAFT = 'draft';
export const WORKFLOW_STATE_IN_EFFECT = 'in_effect';
export const WORKFLOW_STATE_NEW = WORKFLOW_PATH_NEW;
export const WORKFLOW_STATE_REJECTED = 'rejected';
export const WORKFLOW_STATE_UNPAID = 'unpaid';
export const WORKFLOW_STATE_SUBMITTED = 'submitted';
export const WORKFLOW_STATE_WAITING_FOR_PAYMENT = 'waiting_for_payment';
export const WORKFLOW_REGEXP = new RegExp('.*\\/(([1-9]\\d*|new)\\/([1-9]\\d*))$');
export const INFO_WCAG = 'wcag';
export const INFO_GDPR = 'gdpr';
export const STATIC_INFO = '/info';
export const PHASE_CREATE = 'PHASE_CREATE';

export const appUrls = {
  front: 'front/*',
  notAuthorized: '/no/access',
  token: 'token',
  driverPrivacyPolicy: 'driver-privacy-policy',
  error: '/error',
  request: {
    create: (step: string = ':step') => `/create/${step}`,
    edit: (step: string = ':step', id: string = ':id') => `/${id}/${step}`,
    submit: {
      fail: '/request/submit/fail',
      failedPayment: '/request/submit/failedpayment',
      success: '/request/submit/success',
    },
  },
  myRequests: {
    base: '/my-requests',
    detail: '/my-requests/:id',
    sgwDetail: (id: string = ':id') => `/my-requests/sgw/${id}`,
    extension: '/my-requests/:id/extension',
  },
  projectType: '/project-type',
  sgw: {
    request: {
      baseWildcard: '/sgw/requests/*',
      base: '/sgw/requests',
      create: '/create',
      edit: (step: string = ':step', id: string = ':id') => `/${id}/edit/${step}`,
      submitted: '/:id/submitted',
    },
  },
};

// Map constants
export const MAP_BASELAYER_PARAMS = { request: 'getTile', layers: 'GRB_BSK' };
// tslint:disable-next-line:max-line-length
export const MAP_BASELAYER_URL =
  'https://geo.api.vlaanderen.be/GRB/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.3.0&FORMAT=image/png&LAYER=grb_bsk&TILEMATRIXSET=GoogleMapsVL&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}';
export const MAP_SATELLITE_PARAMS = { request: 'getTile', layers: 'OMWRGBMRVL' };

// tslint:disable-next-line:max-line-length
export const MAP_SATELLITE_LAYER_URL =
  'https://geo.api.vlaanderen.be/OMWRGBMRVL/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.3.0&FORMAT=image/png&LAYER=omwrgbmrvl&TILEMATRIXSET=GoogleMapsVL&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}';
export const MAP_CARFREEZONE_URL = 'https://geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer/17';
export const MAP_CARFREEZONE_GATES_URL =
  'https://geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer/16';
export const MAP_DEFAULT_POSITION: LatLngTuple = [51.212082, 4.408321];
export const MAP_MIN_DISTANCE = 1;
export const MAP_DEFAULT_ZOOM = 13;
export const MAP_MAX_ZOOM = 21;
export const MAP_FEATURE_COLOR = '#3388ff';
export const MAP_FEATURE_SELECTED_COLOR = 'green';
export const MAP_FEATURE_CONFLICT_COLOR = 'red';
export const MAP_DEFAULT_LAT_DISTANCE = 0.0001;
export const MAP_DEFAULT_LNG_DISTANCE = 0.0001;
export const MAP_DEFAULT_DRAWINGS_WEIGHT = 10;
export const MAP_BASE_CITY_LAYER_URL =
  'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/basemap_stadsplan_v6/MapServer';
export const MAP_ARC_GIS_URL = 'https://geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer';

export const STRING_MAX_LENGTH = 255;
export const TEXT_MAX_LENGTH = 10000;

// Payment
export const PAYMENT_STATE_NEW = 'new';
export const PAYMENT_STATE_PROCESSING = 'processing';
export const PAYMENT_STATE_PROCESSED = 'processed';
export const PAYMENT_STATE_PARTIALLY_PAID = 'partially_paid';
export const PAYMENT_STATE_OVERPAID = 'overpaid';

// Accessibility
export const MAX_PIXEL_DENSITY = 7;

export const STREET_NUMBER_VALIDATION = /^(?=.*\d)[a-zA-Z\d]{0,13}$/;
export const EXCLUDED_ERROR_STATUSES = [HttpStatusCodes.FORBIDDEN, HttpStatusCodes.UNAUTHORIZED];
export const POSTAL_CODE_REGEX = / \([0-9]{4}\)$/;
