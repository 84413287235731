import IState from '../../state';
import { createSelector } from '@reduxjs/toolkit';
import { selectRequest } from './requests.selectors';
import { APP_DEFAULT_COUNTRY } from '../../common/constants';

export const selectState = (state: IState) => state;
export const selectAlert = ({ alert }: IState) => alert;
export const selectAuthenticated = ({ authenticated }: IState): boolean => !!authenticated;
export const selectBusy = ({ busy }: IState): boolean => !!busy;
export const selectConfirm = ({ confirm }: IState) => confirm;
export const selectCost = ({ cost }: IState) => cost;
export const selectCountries = ({ countries }: IState) => countries || [];
export const selectInitialized = ({ initialized }: IState): boolean => !!initialized;
export const selectMetadata = ({ metadata }: IState) => metadata;
export const selectProfile = (state: IState) => state?.profile;
export const selectProcesses = ({ processes }: IState): number => processes || 0;
export const selectPublicDomainIntakeTypes = ({ publicDomainIntakeTypes }: IState) => publicDomainIntakeTypes || [];
export const selectSelectedPublicDomainIntakeTypes = ({ selectedPublicDomainIntake }: IState) =>
  selectedPublicDomainIntake;
export const selectStep = ({ step }: IState) => step;
export const selectTenant = ({ tenant }: IState) => tenant;
export const selectTitle = ({ title }: IState) => title;

export const getProfileName = createSelector([selectProfile], (profile) =>
  [profile?.firstName, profile?.lastName]
    .filter((n) => n) // will filter null/undefined/''
    .join(' '),
);

export const getDefaultCountry = createSelector([selectCountries], (countries) =>
  countries.find(({ country }) => country === APP_DEFAULT_COUNTRY),
);

export const getInitialProfileValues = createSelector([selectProfile, getDefaultCountry], (profile, defaultCountry) => {
  const { id, street, streetNumber, city, zipCode, ...otherValues } = profile || {};
  return {
    ...otherValues,
    country: profile?.country || defaultCountry,
    contactFirstName: profile?.firstName,
    contactLastName: profile?.lastName,
    contactEmailAddress: profile?.email,
    contactPhoneNumber: profile?.phoneNumber,
  };
});

export const getInitialAsignValues = createSelector(
  [selectRequest, getInitialProfileValues],
  (request, profileValues) => {
    return {
      ...profileValues,
      ...request,
      country: request?.country || profileValues.country,
    };
  },
);

export const getCountryOptions = (excludeBelgium: boolean) =>
  createSelector([selectCountries, getDefaultCountry], (countries, belgium) =>
    countries
      .filter((country) => (excludeBelgium ? country !== belgium : true))
      .map(({ name, country }) => ({ label: name, value: country })),
  );

export const selectNewsflashes = (type: 'A-Sign' | 'SGW') =>
  createSelector(
    [selectMetadata],
    (metadata) => metadata?.newsflashes?.filter((newsflash) => newsflash.type === type) || [],
  );
