import axios, { AxiosRequestConfig } from 'axios';
import { Position } from 'geojson';
import { Environment, IS_STAGING } from '../config/environment';
import {
  HttpStatusCodes,
  IAddress,
  IApplicationFeatures,
  ICost,
  ICountry,
  IGeocode,
  IGeometry,
  IGetLogoutUrlResponse,
  IMetadata,
  IPostMessage,
  IProfile,
  IPublicDomainGeometry,
  IPublicDomainGeometryCoord,
  IPublicDomainGeometrySuggestion,
  IPublicDomainIntake,
  IPublicDomainIntakeType,
  IRequest,
  IRequestBE,
  IRequestConflict,
  IRequestExtension,
  IRequestMessages,
  IRequestPayment,
  IRequestPaymentResponse,
  IRequestReason,
  ISgwPhaseMainLocation,
  ITenant,
  ITokenResponse,
  IUploadedFile,
  IVatResponse,
  StreetNumberGeoCodeRetryPolicy,
  SymphonyResponse,
  TCarFreeZone,
} from '../types';
import { API_GRANTTYPE_REFRTOKEN, appUrls, OAUTH_SCOPE, TENANT_NAME } from './constants';
import { GeometryType, PaymentType, PublicDomainType } from './enums';
import { addQueryString, getApiGeneralUrl, getApiOauthUrl, getApiUserUrl, queryParameter } from './utils/apiUtils';
import { addressToString, getGeometryForIntake, intakeToAddresses, intakeToAddressString } from './utils/geometry.util';
import { cleanRequestForApi, cleanRequestForApp, cleanRequestForCostCalculationApi } from './utils/requestUtils';
import { mapMainLocationToAddress } from './utils/sgwPhaseUtils';
import { getUrl, joinUrl } from './utils/urlUtils';
import { Store } from 'redux';
import { History } from 'history';
import { ApplicationActions } from '../store/actions/workflow.actions';
import { goToAStadLogin } from '../store/sagas/login.sagas';
import { removeTokenData, storeOriginalPath } from './utils/token.utils';
import { LatLngTuple } from 'leaflet';
import { ensureBelgianCoordinateFormat } from './utils/mapUtils';

export const setupInterceptors = (history: History, store: Store) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === HttpStatusCodes.UNAUTHORIZED && error.response?.data?.error === 'invalid_grant') {
        storeOriginalPath(window.location.pathname);
        const originalRequestConfig: AxiosRequestConfig = error.config;
        store.dispatch(ApplicationActions.refreshToken(originalRequestConfig));
      } else if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
        removeTokenData();
        goToAStadLogin();
      }
    },
  );
};

export const getOAuthURL = () =>
  addQueryString(
    getUrl(Environment.oauthParamsAntwerpen.endpointAuth),
    queryParameter('client_id', Environment.oauthParamsAntwerpen.clientId),
    queryParameter('locale', 'nl'),
    queryParameter('redirect_uri', Environment.oauthParamsAntwerpen.redirectUri, true),
    queryParameter('response_type', 'token'),
    queryParameter('scope', OAUTH_SCOPE),
    queryParameter(
      'auth_methods',
      'iam-aprofiel-userpass,fas-citizen-bmid,fas-citizen-eid,fas-citizen-totp,fas-citizen-otp',
    ),
    queryParameter('minimal_assurance_level', 'low'),
  );

export async function getExchangeToken(token: string): Promise<ITokenResponse> {
  const data = new FormData();
  data.append('access_token', token);
  data.append('client_id', Environment.oauthParams.clientId);
  data.append('client_secret', Environment.oauthParams.clientSecret!);
  data.append('grant_type', Environment.oauthParams.grantType!);

  const response = await axios.request({
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'post',
    url: Environment.oauthParams.endpoint,
  });
  return response.data;
}

export async function getApplicationFeatures(): Promise<IApplicationFeatures> {
  const response = await axios.get(getApiGeneralUrl('app_features'));
  return unwrapSymphonyResponse(response);
}

export async function getRefreshToken(refreshToken: string): Promise<ITokenResponse> {
  const data = new FormData();
  data.append('client_id', Environment.oauthParams.clientId);
  data.append('client_secret', Environment.oauthParams.clientSecret!);
  data.append('grant_type', API_GRANTTYPE_REFRTOKEN);
  data.append('refresh_token', refreshToken);

  const query = Array.from((data as any).keys())
    // @ts-ignore
    .map((x: string) => `${x}=${data.get(x)!.toString()}`)
    .join('&');

  const response = await axios.get(Environment.oauthParams.endpoint + '?' + query);
  return response?.data;
}

export async function getProfile(): Promise<IProfile> {
  const response = await axios.get(getApiUserUrl('v1')('me'));
  return unwrapSymphonyResponse(response);
}

export async function getLogoutUrl(redirectUri: string): Promise<IGetLogoutUrlResponse> {
  const response = await axios.post(getApiOauthUrl('logoutUrl'), { redirectUri: redirectUri });
  return response.data;
}

export async function getReasons(): Promise<IRequestReason[]> {
  const response = await axios.get(getApiUserUrl('v1')('requests', 'reasons'));
  return unwrapSymphonyResponse(response);
}

export async function getMetadata(): Promise<IMetadata> {
  const response = await axios.get(getApiGeneralUrl('metadata'));
  return unwrapSymphonyResponse(response);
}

export async function getCountries(): Promise<ICountry[]> {
  const response = await axios.get(getApiGeneralUrl('countries'));
  return unwrapSymphonyResponse(response);
}

export async function getTenant(): Promise<ITenant> {
  const response = await axios.get(getApiGeneralUrl('tenant'));
  return unwrapSymphonyResponse(response);
}

export async function getPublicDomainIntakeTypes(): Promise<IPublicDomainIntakeType> {
  const response = await axios.get(getApiGeneralUrl('publicDomainIntake', 'types'));
  return unwrapSymphonyResponse(response);
}

export async function getRequest(id: string): Promise<IRequestBE> {
  const response = await axios.get(getApiUserUrl('v1')('requests', id));
  return unwrapSymphonyResponse(response);
}

export async function getMyRequests(order?: string, sort?: string): Promise<IRequest[]> {
  const response: SymphonyResponse<IRequest[]> = await axios.get(
    addQueryString(
      getApiUserUrl('v1')('requests'),
      queryParameter('page_size', IS_STAGING ? 100 : 100000), // only render max 1000 requests on staging, to prevent request call from timing out
      queryParameter('order', order),
      queryParameter('sort', sort),
    ),
  );
  return unwrapSymphonyResponse(response);
}

export async function saveRequest(request: IRequest, carFreeZones: TCarFreeZone[]): Promise<IRequest> {
  let response: SymphonyResponse<IRequestBE>;
  if (request.id) {
    response = await axios.put(
      getApiUserUrl('v1')('requests', request.id.toString()),
      cleanRequestForApi(request, carFreeZones),
    );
  } else {
    response = await axios.post(getApiUserUrl('v1')('requests'), cleanRequestForApi(request, carFreeZones));
  }
  return cleanRequestForApp(unwrapSymphonyResponse(response), carFreeZones);
}

export async function partialSaveRequest(request: Partial<IRequest>, carFreeZones: TCarFreeZone[]): Promise<IRequest> {
  let response: SymphonyResponse<IRequestBE>;
  if (request.id) {
    response = await axios.patch(
      getApiUserUrl('v1')('requests', request.id.toString()),
      cleanRequestForApi(request as IRequest, carFreeZones),
    );
    return cleanRequestForApp(unwrapSymphonyResponse(response), carFreeZones);
  } else {
    throw new Error("Request provided doesn't have an id");
  }
}

export async function getVatInfo(vatNumber: string): Promise<IVatResponse> {
  const response: SymphonyResponse<IVatResponse> = await axios.get(
    addQueryString(getApiGeneralUrl('checkVat'), { key: 'vat', value: vatNumber }),
  );
  return unwrapSymphonyResponse(response);
}

const geoCodeAddressCache: { [id: string]: Promise<SymphonyResponse<IGeocode>> } = {};

export function geoCodeAddress(
  address: IAddress,
  signal?: AbortSignal,
): Promise<SymphonyResponse<IGeocode> | undefined> {
  const cacheKey = addressToString(address);
  if (!geoCodeAddressCache[cacheKey]) {
    geoCodeAddressCache[cacheKey] = axios.get(
      addQueryString(getApiGeneralUrl('geolocation', 'geocode'), {
        key: 'address',
        value: addressToString(address),
      }),
      { signal },
    );
  }
  return geoCodeAddressCache[addressToString(address)];
}

export const findValidAddressToGeoCode = async (location: ISgwPhaseMainLocation) =>
  (await getCoordinates(location, StreetNumberGeoCodeRetryPolicy.calculateAvg)) ||
  (await getCoordinates(location, StreetNumberGeoCodeRetryPolicy.useFrom)) ||
  (await getCoordinates(location, StreetNumberGeoCodeRetryPolicy.useTo)) ||
  getCoordinates(location, StreetNumberGeoCodeRetryPolicy.useEmpty);

export const getCoordinates = async (location: ISgwPhaseMainLocation, retryPolicy: StreetNumberGeoCodeRetryPolicy) => {
  const address = mapMainLocationToAddress(location, retryPolicy);
  const response = await geoCodeAddress(address);
  const coord = response?.data.data?.point?.coordinates as unknown as LatLngTuple;

  if (!coord) return null;
  return ensureBelgianCoordinateFormat(coord as Position) || null;
};

export function geoCodeReverse(coordinates: IPublicDomainGeometryCoord): Promise<SymphonyResponse<IAddress>> {
  return axios.get(
    addQueryString(
      getApiGeneralUrl('geolocation', 'reverse'),
      { key: 'lon', value: coordinates![0] },
      { key: 'lat', value: coordinates![1] },
    ),
  );
}

export async function geoCodeSuggestion(
  street: string,
  city: string,
  zipCode?: number,
): Promise<IPublicDomainGeometrySuggestion[]> {
  const response: SymphonyResponse<IPublicDomainGeometrySuggestion[]> = await axios.get(
    addQueryString(getApiGeneralUrl('geolocation', 'suggestions'), {
      key: 'query',
      value: `${street} ${zipCode || city}`,
    }),
  );
  return unwrapSymphonyResponse(response);
}

export async function suggestStreet(street: string): Promise<string[]> {
  const response: SymphonyResponse<IPublicDomainGeometrySuggestion[]> = await axios.get(
    addQueryString(getApiGeneralUrl('geolocation', 'suggestions'), {
      key: 'query',
      value: `${street}, ${TENANT_NAME}`,
    }),
  );
  const data = unwrapSymphonyResponse(response);
  if (data && data.length > 0) {
    return data.map((x) => x.label);
  }
  return [];
}

export async function getAreaSizeByGeometry(geometry: IGeometry): Promise<string> {
  const response: SymphonyResponse<{ area: string }> = await axios.post(getApiGeneralUrl('geometry_area'), geometry);
  const data = unwrapSymphonyResponse(response);
  return data?.area || '';
}
export async function geoCodeIntake(
  intake: IPublicDomainIntake,
): Promise<{ geometry: IPublicDomainGeometry; intake: IPublicDomainIntake }> {
  try {
    // Prepare
    const geometry = getGeometryForIntake(intake);
    geometry.coordinates = [];

    // Check
    let suggestions;
    const zipCodeMatch = intake.street!.match(/\d{4}/);
    if (zipCodeMatch && zipCodeMatch.length) {
      const zipCode = parseFloat(zipCodeMatch[0]);
      const intakeStreet = intake.street!.substring(0, intake.street!.length - 7);
      intake.zipCode = zipCode;
      intake.street = intakeStreet;
      suggestions = await geoCodeSuggestion(intakeStreet!, TENANT_NAME, zipCode);
    } else {
      suggestions = await geoCodeSuggestion(intake.street!, TENANT_NAME);
    }
    if (!suggestions || suggestions.length === 0) {
      throw new Error('Not a valid address: no suggestions found');
    }

    // Geocode
    const addresses: IAddress[] = intakeToAddresses(intake);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < addresses.length; i++) {
      let response = await geoCodeAddress(addresses[i]);
      if (!response?.data?.data && !!addresses[i].zipCode) {
        response = await geoCodeAddress({ ...addresses[i], zipCode: undefined });
      }
      geometry.coordinates[i] = response?.data.data.point.coordinates as any;
    }
    const reversedResponse = await geoCodeReverse(geometry.coordinates[0]);
    intake.zipCode = reversedResponse.data.data.zipCode;
    intake.city = reversedResponse.data.data.city;

    // Don't allow same origin for lines
    const secondCoordinateEqualsFirst =
      geometry.coordinates.length > 1 &&
      geometry.coordinates[0][0] === geometry.coordinates[1][0] &&
      geometry.coordinates[0][1] === geometry.coordinates[1][1];
    const onlyOneCoordinate = geometry.coordinates.length === 1;

    if (
      (geometry.type === GeometryType.LineString || geometry.type === GeometryType.Polygon) &&
      (secondCoordinateEqualsFirst || onlyOneCoordinate)
    ) {
      const coordinates: [number, number] = [geometry.coordinates[0][0] - 0.0002, geometry.coordinates[0][1] - 0.0001];
      if (onlyOneCoordinate) {
        geometry.coordinates.push(coordinates);
      } else {
        geometry.coordinates[1] = coordinates;
      }
    }

    return { geometry, intake };
  } catch (e) {
    throw intakeToAddressString(intake);
  }
}

export async function saveAttachment(file: File): Promise<string[]> {
  try {
    const data = new FormData();
    data.append('file', file);

    const response = await axios.post(getApiGeneralUrl('storage'), data);

    return unwrapSymphonyResponse(response);
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getCostCalculation(request: IRequest, carFreeZones: TCarFreeZone[]): Promise<ICost> {
  const response: SymphonyResponse<ICost> = await axios.post(
    getApiUserUrl('v1')('requests', 'calculate_cost'),
    cleanRequestForCostCalculationApi(request, carFreeZones),
  );

  return unwrapSymphonyResponse(response);
}

export async function getExtensionCostCalculation(
  request: IRequest,
  extension: Partial<IRequestExtension>,
): Promise<ICost> {
  const response: SymphonyResponse<ICost> = await axios.post(
    getApiUserUrl('v1')('requests', request.id, 'extensions', 'calculate_cost'),
    extension,
  );

  return unwrapSymphonyResponse(response);
}

export async function saveRequestExtension(
  request: IRequest,
  extension: Partial<IRequestExtension>,
): Promise<IRequestExtension> {
  const response: SymphonyResponse<IRequestExtension> = await axios.post(
    getApiUserUrl('v1')('requests', request.id, 'extensions'),
    extension,
  );

  return unwrapSymphonyResponse(response);
}

export async function initializePayment(
  payment: IRequestPayment,
  request?: IRequest,
  integrateDigipolisSalesIntegration?: boolean,
): Promise<IRequestPaymentResponse> {
  const type = request?.paymentType ?? PaymentType.Online;

  const url = addQueryString(
    getApiUserUrl(integrateDigipolisSalesIntegration ? 'v2' : 'v1')('payments', payment.id, 'cart'),
    queryParameter(
      'successUrl',
      joinUrl(Environment.frontEndHost, getUrl(Environment.baseFrontEndUrl, appUrls.request.submit.success)),
    ),
    queryParameter(
      'failureUrl',
      joinUrl(Environment.frontEndHost, getUrl(Environment.baseFrontEndUrl, appUrls.request.submit.failedPayment)),
    ),
    integrateDigipolisSalesIntegration ? null : queryParameter('type', type),
  );
  const response: SymphonyResponse<IRequestPaymentResponse> = await (integrateDigipolisSalesIntegration
    ? axios.post(url)
    : axios.get(url));

  return unwrapSymphonyResponse(response);
}

export async function getConflicts(request: IRequest, geometry: IPublicDomainGeometry) {
  const url = addQueryString(
    getApiUserUrl('v1')('requests', 'conflicts'),
    queryParameter('dateFrom', request.dateFrom),
    queryParameter('dateUntil', request.dateUntil),
    queryParameter('geometry', JSON.stringify(geometry)),
  );

  const response: SymphonyResponse<IRequestConflict[]> = await axios.get(url);

  return unwrapSymphonyResponse(response);
}

export async function patchCarfreezone(request: IRequest, intake: IPublicDomainIntake): Promise<IPublicDomainIntake> {
  if (intake.type.type === PublicDomainType.CarfreeZone) {
    const response: SymphonyResponse<IPublicDomainIntake> = await axios.patch(
      getApiUserUrl('v1')('requests', request.id, 'carfreezoneintakes', intake.id),
      { permittedPlates: intake.permittedPlates },
    );

    return unwrapSymphonyResponse(response);
  } else {
    throw new Error(`The intake is of type ${intake.type.type} instead of ${PublicDomainType.CarfreeZone}`);
  }
}

function unwrapSymphonyResponse<T>(response: SymphonyResponse<T>): T {
  if (response && response.data && response.data.data !== undefined) {
    return response.data.data;
  }
  throw new Error(`Symphony response is empty: ${response}`);
}

export async function getRequestMessages(id: string): Promise<IRequestMessages> {
  const response = await axios.get(getApiUserUrl('v1')('requests', id, 'messages'));
  return unwrapSymphonyResponse(response);
}

export async function postRequestMessage(postMessage: IPostMessage): Promise<IRequestMessages> {
  const response: SymphonyResponse<IRequestMessages> = await axios.post(
    getApiUserUrl('v1')('requests', postMessage.requestId.toString(), 'messages'),
    {
      attachments: postMessage.message.attachments,
      content: postMessage.message.content,
    },
  );
  return unwrapSymphonyResponse(response);
}

export async function uploadFileToStorage(
  file: FormData,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<IUploadedFile> {
  const response: SymphonyResponse<IUploadedFile> = await axios.post(getApiGeneralUrl('storage'), file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return unwrapSymphonyResponse(response);
}

export async function ping(): Promise<void> {
  await fetch(getUrl(Environment.baseFrontEndUrl, 'pingResource.txt'));
}
