import { ICountry } from './address.types';
import { ICost } from './cost.types';
import { IPublicDomainGeometry, IPublicDomainIntake, IPublicDomainIntakeBE } from './publicDomainIntake.types';
import { ApplicantType, PaymentType, RequestReasons } from '../common/enums';
import { IPincode } from './pincode.types';
import { IRequestMessage } from './requestMessages.types';

export enum MessageState {
  AwaitingFeedback = 'awaiting_feedback',
  FeedbackReceived = 'feedback_received',
  NotInFeedbackLoop = 'not_in_feedback_loop',
}

interface IBaseRequest {
  cost: ICost;
  dateUntil: string;
  emergencyProcedure: boolean;
  id?: number;
  payments: IRequestPayment[];
  state?: IRequestState;
  messageState?: MessageState;
}

interface IRequestACL {
  can_edit_attachments: boolean;
  can_add_extensions: boolean;
  messages_can_view: boolean;
  messages_can_edit: boolean;
  messages_can_resolve: boolean;
}

export interface IRequest extends IBaseRequest {
  acl?: IRequestACL;
  applicantType?: ApplicantType;
  attachments?: IRequestFile[];
  bus?: string;
  city: string;
  comment: string;
  companyId?: string;
  companyName?: string;
  country: ICountry;
  createdAt: string;
  dateFrom: string;
  bankPaymentDay: string;
  emailAddress: string;
  emergencyProcedure: boolean;
  entireDay: boolean;
  extensions?: IRequestExtension[];
  firstName?: string;
  ibanNumber?: string;
  lastName?: string;
  latestRequestDateForExtension: string;
  latestRequestDateTimeForEmergencyExtension: string;
  messages?: IRequestMessage[];
  needsPayment: boolean;
  onlyOnWeekdays: boolean;
  originalDateUntil: string;
  paymentType?: PaymentType;
  permit?: IRequestPermit;
  pincode?: IPincode;
  phoneNumber: string;
  priority: IRequestPriority;
  publicDomainIntakes: IPublicDomainIntake[];
  reason: IRequestReason;
  reasonForRejection?: string;
  referenceId: string;
  requestAddress?: {
    street: string;
    streetNumber: string;
  };
  ssn?: string;
  birthday?: string;
  street: string;
  streetFlag?: boolean;
  streetNumber: string;
  timeFrom: string;
  timeUntil: string;
  zipCode: string;
  sgwRequestId?: number;
}

export interface IRequestBE extends Omit<IRequest, 'publicDomainIntakes'> {
  publicDomainIntakes: IPublicDomainIntakeBE[];
}

export interface IRequestPriority {
  priority: number;
  name: string;
}

export interface IRequestReason {
  acl: {
    showOnFrontOffice: boolean;
  };
  displayName: string;
  displayRemarks: string;
  displayImageDescription?: string;
  name: string;
  needsPayment: boolean;
  reason: RequestReasons;
}

export interface IRequestState {
  name?: string;
  state: string;
  transitions?: string[];
}

export interface IRequestFile extends File {
  createdAt?: string;
  file?: string;
  fileName?: string;
  id?: string;
  mimeType?: string;
}

export interface IRequestPayment {
  amountPaid: number;
  amountToBePaid: number;
  canPay: boolean;
  lastPaymentDate: string | null;
  id: number;
  ogm: string;
  state: IRequestState;
  type: { type: string; name: string };
}

export interface IRequestPaymentResponse {
  failureUrl: string;
  ogoneUrl: string;
  paymentUrl: string;
  successUrl: string;
}

export interface IRequestConflict {
  index?: number;
  feature: {
    geometry: IPublicDomainGeometry;
    properties: {
      description: string;
      id: number;
      title: string;
      type: string;
    };
  };
}

export interface IRequestLicencePlate {
  countryCode: string;
  licensePlate: string;
  startDate: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IRequestExtension extends IBaseRequest {
  permitRequest: IRequest;
}

export interface IRequestPermit {
  downloadUrl?: string;
  pending?: boolean;
}
