import { Visible } from '../../common/layout/Visible.component';
import * as A from 'antwerp-core-react-branding';
import { FC, useCallback, useEffect } from 'react';
import {
  APP_ALLOWED_FILE_FORMATS,
  APP_CONTACT_DEVIATIONS_DELIJN,
  APP_MAX_ATTACH_SIZE,
} from '../../../common/constants';
import { ApplicantType } from '../../../common/enums';
import { validateAttachmentStep5 } from '../../../common/validation/validateRequiredAttachment';
import { ApplicantTypeSelector } from '../../common/forms/applicantTypeSelector.component';
import { AddressInput } from './addressInput';
import { CompanyInput } from './companyInput';
import { PersonalInput } from './personalnput';
import '../forms.scss';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IPersonalForm, PersonalFormFields } from '../../../common/form';
import { getInitialAsignValues, selectRequest, selectRequestAttachments } from '../../../store/selectors';
import { Required } from '../../common/forms/Required.component';
import { RequestActions } from '../../../store/actions';
import { submitId, isValid } from '../../../common/utils/formUtils';
import { FileSelect, WorkflowButtons } from '../../molecules';
import { Fieldset } from '../../atoms';
import { ICost, IRequest, IWaitableProperties, WorkflowType } from '../../../types';
import { translate } from '../../../translations/translate';

export type PersonalFormProperties = {
  cost: ICost;
  onPrevious: (value: IRequest) => void;
  onSubmit: (values: IRequest) => void;
  step?: number;
} & IWaitableProperties;
/**
 * React Component PeriodForm
 */
export const PersonalForm: FC<PersonalFormProperties> = ({ cost, onSubmit, onPrevious, step, busy }) => {
  const defaultValues = useSelector(getInitialAsignValues);
  const request = useSelector(selectRequest);
  const attachments = useSelector(selectRequestAttachments);
  const formMethods = useForm<IPersonalForm>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    setError,
    setValue,
    watch,
    register,
  } = formMethods;
  const dispatch = useDispatch();
  const currentValues = watch();

  useEffect(() => {
    setValue('attachments', attachments);
  }, [attachments, setValue]);

  const applicantTypeLabel = translate('personalform.whoareyou');
  const labelMaxAttachSize = translate('reasonform.maxattachsize', { maxsize: APP_MAX_ATTACH_SIZE });
  const labelAttachmentInfo = translate('fields.attachment');

  const onDeleteAttachment = () => {
    setValue('attachments', undefined);
    dispatch(RequestActions.storeAttachment([]));
  };

  const onChangeAttachment = (attachment?: File | null): void => {
    if (!attachment) return;

    const error = validateAttachmentStep5([attachment], getValues());
    if (isValid(error)) {
      clearErrors('attachments');
      setValue('attachments', [attachment]);
      dispatch(RequestActions.saveAttachment(attachment));
    } else {
      setError('attachments', { type: 'manual', message: error as string });
    }
  };

  const _onSubmit = useCallback(
    (values: Partial<IRequest>) => {
      onSubmit({
        ...request!,
        ...values,
        // AS-6777 When a FO-user who has previously created a request for a company he keeps on doing that even when he selects "Burger" (Civilian)
        companyId: values.applicantType === ApplicantType.Company ? values.companyId : undefined,
        ssn: values.applicantType === ApplicantType.Company ? undefined : values.ssn,
      });
    },
    [onSubmit, request],
  );

  return (
    <Visible visible={!!(currentValues && step)}>
      <section>
        <FormProvider {...formMethods}>
          {/*@ts-ignore*/}
          <A.Form onSubmit={handleSubmit(_onSubmit)} id={submitId(WorkflowType.ASign, step)}>
            <Fieldset legend={translate('personalform.title')}>
              <ApplicantTypeSelector name={PersonalFormFields.applicantType} label={applicantTypeLabel} required />
              {/*@ts-ignore*/}
              <A.Spacing type={A.SpacingStyle.MarginBottom} />
              <PersonalInput cost={cost} currentValues={currentValues} />
            </Fieldset>
            {currentValues.applicantType === ApplicantType.Company && (
              <Fieldset legend={translate('personalform.companydata')}>
                <CompanyInput />
              </Fieldset>
            )}
            <Fieldset legend={translate('personalform.address')}>
              <AddressInput />
            </Fieldset>
            <Fieldset legend={translate('reasonform.attachmentsTitle')}>
              {/*@ts-ignore*/}
              <A.Paragraph spacing>{translate('reasonform.attachmentsInfo')}</A.Paragraph>
              <FileSelect
                {...register('attachments', { required: false })}
                info={labelAttachmentInfo}
                uploadedFiles={currentValues.attachments || []}
                accept={APP_ALLOWED_FILE_FORMATS}
                description={labelMaxAttachSize}
                onFileSelect={onChangeAttachment}
                onDeleteAll={onDeleteAttachment}
              />
              <Required
                required={!!errors?.attachments}
                //@ts-ignore
                message={errors?.attachments?.message || translate(`fields.attachmentRequired`)}
              />
              {/*@ts-ignore*/}
              <A.Spacing type={A.SpacingStyle.MarginTop} className="m-alert">
                {/*@ts-ignore*/}
                <A.Paragraph>{translate('reasonform.attachmentsBusInfo')}</A.Paragraph>
                {/*@ts-ignore*/}
                <A.Paragraph>
                  <strong>{translate('reasonform.attachmentsContactBus')}: </strong>
                  <a href={`mailto:${APP_CONTACT_DEVIATIONS_DELIJN}`}>{APP_CONTACT_DEVIATIONS_DELIJN}</a>
                </A.Paragraph>
              </A.Spacing>
            </Fieldset>
            <WorkflowButtons step={step} onPrevious={onPrevious} type={WorkflowType.ASign} />
          </A.Form>
        </FormProvider>
      </section>
    </Visible>
  );
};
