import intl from 'react-intl-universal';
import { createInfoAlertAction } from '../common/actions/creators/alert';
import { getVatInfo } from '../common/api';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { selectCountries } from '../store/selectors';

export const useVATValidationGenerator: (
  shouldValidate: boolean,
  vatNumberField: string,
  companyNameField: string,
) => (vatValue: string) => Promise<ValidateResult> = (shouldValidate, vatNumberField, companyNameField) => {
  const { setError, setValue, trigger } = useFormContext();
  const companyName = useWatch({ name: companyNameField });
  const countries = useSelector(selectCountries);
  const dispatch = useDispatch();

  return async (vatValue: string) => {
    if (!shouldValidate) return true;
    try {
      const response = await getVatInfo(vatValue || '');
      if (!response.valid) {
        setError(vatNumberField, {
          type: 'manual',
          message: intl.get(`fields.NoValidVat.${response.issuedBy || 'general'}`),
        });
        return intl.get(`fields.NoValidVat.${response.issuedBy || 'general'}`);
      }

      if (response.vatNumber) {
        setValue(vatNumberField, response.vatNumber);
      }

      if (response.name) {
        setValue(companyNameField, response.name);
        await trigger(companyNameField);
      } else if (!companyName) {
        dispatch(createInfoAlertAction(intl.get('personalform.vatvalidbutnotfound', { vat: vatValue })));
        setError(companyNameField, {
          type: 'manual',
          message: intl.get('fields.required'),
        });
      }

      if (response.city) {
        setValue('city', response.city, { shouldValidate: true });
      }

      if (response.zipCode) {
        setValue('zipCode', response.zipCode, { shouldValidate: true });
      }

      if (response.countryCode) {
        setValue(
          'country',
          countries.find(({ country }) => country === response.countryCode),
          { shouldValidate: true },
        );
      }

      if (response.address) {
        const addressInput = response.address.split(' ');

        setValue('street', addressInput.slice(0, addressInput.length - 1).join(' '), { shouldValidate: true });
        setValue('streetNumber', addressInput[addressInput.length - 1], { shouldValidate: true });
      }
    } catch (e) {
      return intl.get('fields.NoValidVat.general');
    }

    return true;
  };
};
