import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { Snackbar as MaterialSnackBar } from '@mui/material';
import { ICON_COLORS } from '../../../theme';
import { SnackbarContent } from '@mui/material';
import { SnackBarVariant } from '../../../types';
import { createUseStyles } from 'react-jss';

interface IProps extends PropsWithChildren {
  duration?: number | undefined;
  onClose?: () => void;
  show: boolean;
  variant?: SnackBarVariant;
}

const useStyles = createUseStyles({
  container: { zIndex: `100001 !important` },
  error: { backgroundColor: `${ICON_COLORS.danger} !important` },
  success: { backgroundColor: `${ICON_COLORS.success} !important` },
});

export const Snackbar: FunctionComponent<IProps> = ({
  children,
  duration,
  onClose,
  show = false,
  variant = SnackBarVariant.success,
}) => {
  const [open, setOpen] = useState<boolean>(show);
  const C = useStyles();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const _onClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <MaterialSnackBar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={duration === Infinity ? null : duration}
      className={C.container}
      onClose={_onClose}
      open={open}
    >
      <SnackbarContent
        message={<span>{children}</span>}
        className={variant === SnackBarVariant.success ? C.success : C.error}
        role="alert"
      />
    </MaterialSnackBar>
  );
};
