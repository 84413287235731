import { IPublicDomainGeometry } from './publicDomainIntake.types';
import { IWithId } from './generic.types';

export interface ICountry {
  country: string;
  name: string;
}

export interface IZip {
  name: string;
  zipCode: number;
}

export interface IAddress {
  bus?: string;
  city?: string;
  country?: ICountry;
  street: string;
  streetNumber?: string;
  zipCode?: string | number;
}

export interface IAddressBE extends Omit<IAddress, 'country'> {
  country?: string;
}

export interface IGeocode {
  point: IPublicDomainGeometry;
  address: {
    streetNumber?: string;
    streetName?: string;
    city?: string;
    postalCode?: string;
  };
}

export interface IPhaseAddress extends IWithId {
  streetName: string;
  streetNumberFrom?: string;
  streetNumberTo?: string;
}

export const enum StreetNumberGeoCodeRetryPolicy {
  useEmpty,
  useTo,
  useFrom,
  calculateAvg,
}
