import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { geoCodeAddress } from '../api';
import { POSTAL_CODE_REGEX, TENANT_NAME } from '../constants';
import { translate } from '../../translations/translate';

export const validateAddress = async (
  street?: string,
  streetNumber?: string,
  errorMessage?: string,
  zipCode?: string,
): Promise<ValidateResult> => {
  if (!street) {
    return true;
  }

  const response = await geoCodeAddress({
    street,
    streetNumber: streetNumber,
    city: TENANT_NAME,
    zipCode,
  });

  if (
    !response?.data ||
    !(response?.data?.data?.address?.streetName?.toLowerCase() === street.replace(POSTAL_CODE_REGEX, '').toLowerCase())
  ) {
    return errorMessage || translate('locationsform.invalidAddress');
  }

  return true;
};
